<script>
export default {
  name: 'CustomTitle',
  props: {
    productId: {
      type: Number,
    },
    value: {
      type: String
    }
  },
}
</script>

<template>
  <span>
    <span v-if="productId === 25765">С кэшбеком 5% при оплате картой от Альфа Банка</span>
    <span v-else-if="productId === 26182" class="skuratov">Интернет-магазин</span>
    <span v-else v-html="value"></span>
  </span>
</template>

<style scoped>
.skuratov {
  font-weight: bold;
  font-style: italic;
}
</style>
