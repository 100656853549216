<script>
export default {
  name: 'Subtitle',
  props: {
    productId: {
      type: Number,
    },
    value: {
      type: String
    }
  }
}
</script>

<template>
  <div>
    <div v-if="productId === 26182" class="skuratov">{{ value }}</div>
  </div>
</template>

<style scoped>
.skuratov {
  font-weight: bold;
  font-style: italic;
}
</style>
